import { cloneDeep, omit, remove } from 'lodash';
import { ProductTypeEnum } from 'types/schema';

import { UserProduct } from '../types';
import useProductContentTransform from './useProductContentTransform';

const useTransformProducts = (products: UserProduct[]) => {
  products.forEach((product) => {
    if (product.type === ProductTypeEnum.Collection) {
      const childProducts = (product?.childProducts || []).map((p) => {
        /* TODO: remove cloneDeep */
        const _product = cloneDeep(p) as UserProduct;

        if (_product && _product.id) {
          _product.isChildProduct = true;
          _product.parentProduct = omit(product, 'children');
        }

        return _product;
      });

      product.children = [];
      if (childProducts.length) {
        product.children = childProducts;
      }
    }
  });

  const { products: _products } = useProductContentTransform(products);

  return { products, fetching: false };
};

export default useTransformProducts;
