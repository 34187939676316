/* const statusMapping = {
  'NOT_STARTED': 'notAttempted',
  'IN_PROGRESS': 'inProgress',

}; */

import { State } from '../types';

const transformPerformance = (performances: any[] = []) => {
  const states: State[] = [];
  performances.forEach((item) => {
    if (item.entityId) {
      states.push({
        contentId: item.entityId,
        progress: Math.round(item.progress),
        completionStatus: item.status,
        completedOn: item.completedAt,
        certificate: item.issuedCertificate,
      });
    }
  });
  return states;
};

export default transformPerformance;
