import sanitizeURL from 'utils/sanitizeUrl';

import PaymentProviders from './paymentProviders';
import { PaymentProvidersInput } from './types';

const handlePaymentProvider = async ({
  checkoutPayload,
  product,
  redirectUrl,
  user,
  sitePaymentInfo,
}: PaymentProvidersInput) => {
  const handled = await PaymentProviders[checkoutPayload.paymentProvider]({
    checkoutPayload,
    product,
    redirectUrl,
    user,
    sitePaymentInfo,
  });

  if (!handled || !checkoutPayload.success) {
    console.error('Error in payment');
    window.location.href = sanitizeURL(redirectUrl);
  }
};

export default handlePaymentProvider;
