import { DiscountTypeEnum, PricingParameters, PricingType } from 'types/schema';

import isDiscountValid from './discountValid';

const getDiscountedPrice = (pricingParameters: PricingType) => {
  const { price, discount } = pricingParameters;
  const { amount: discountAmount = 0, type: discountType = DiscountTypeEnum.Fixed } =
    discount || {};

  let discountedPrice = 0;

  const productPrice = price || 0;
  /* if >= 0 */
  if (discountAmount || discountAmount === 0) {
    if (discountType === DiscountTypeEnum.Fixed) {
      discountedPrice = productPrice - discountAmount;
    } else {
      discountedPrice = parseFloat(
        (productPrice - (discountAmount * productPrice) / 100).toFixed(2),
      );
    }
  } else if (!discountAmount) {
    /* if null or undefined */
    const fPrice = parseInt(productPrice.toFixed(0), 10) || 0;
    discountedPrice = fPrice;
  }

  return discountedPrice < 0 ? 0 : discountedPrice;
};

const calculateDiscount = (props: PricingType) => {
  const { price, discount, validity, currency } = props || {};
  const { amount: discountAmount = 0, validity: discountValidity } = discount || {};

  const discountAvailable = isDiscountValid(discountValidity || '');

  if (!price) {
    return { price: 0, discountedPrice: 0, discountValue: discountAvailable ? discountAmount : 0 };
  }
  const fPrice = parseInt((price || 0).toFixed(0), 10) || 0;

  const discountedPrice = getDiscountedPrice(props);

  return {
    price: fPrice,
    discountValue: discountAvailable ? discountAmount : 0,
    discountedPrice: discountAvailable ? discountedPrice : fPrice,
    isDiscounted: discountAvailable && discountAmount > 0,
  };
};

export default calculateDiscount;
