import { ProductFragment } from 'data-op/fragments/generated/product';
import { IssuedCertificateFragmentFragment } from 'modules/contentAffiliations/affiliations/courseV2/graphql/fragments/generated/IssuedCertificate';
import {
  DiscountTypeEnum,
  Media,
  PerformanceStatusEnum,
  ProductContentTypeEnum,
} from 'types/schema';

export enum ProductStatusEnum {
  Archived = 'ARCHIVED',
  Listed = 'LISTED',
  Unlisted = 'UNLISTED',
  Draft = 'DRAFT',
}

export interface MetricsType {
  earnings: number;
  learners: number;
  conversion: number;
  items: number;
}

export interface Offering {
  type: string;
  offeringId?: string;
  name: string;
  duration: number;
  description: string;
  media: Array<Media>;
}

export interface PricingParams {
  price: number;
  discount: {
    type: DiscountTypeEnum;
    amount: number;
    validity: string | null;
  };
  validity: string;
  currency?: string;
  coupons?: Array<string>;
}

export type UserPerformance = {
  contentId: string;
  progress: number;
  completionStatus: PerformanceStatusEnum;
  completedOn: string;
  certificate?: IssuedCertificateFragmentFragment;
};

export enum UserProductStatusEnum {
  NotPurchased = 'NOT_PURCHASED',
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export type PurchasableProduct = Omit<UserProduct, 'children'>;

export interface PurchaseInfo {
  isFree: boolean;
  isDiscounted: boolean;
  price: number;
  finalPrice: number;
  discountAmount: number;
  discountType: DiscountTypeEnum;
  discountValidity: string;
  currency: string;
  productId: string;
  purchasableProduct?: Omit<UserProduct, 'children'> | null;
}

export enum CertificateVersions {
  V1 = 'V1',
  V2 = 'V2',
}

export type CertificateFields = {
  awared: boolean;
  id: string;
  completionDate: string;
  certificateVersion?: CertificateVersions;
};

export interface DerivedProductFields {
  userProductStatus: UserProductStatusEnum | PerformanceStatusEnum;
  enrolled: boolean;
  notStarted: boolean;
  inProgress: boolean;
  completed: boolean;
  purchaseInfo: PurchaseInfo;
}

export type ProductCustomContent = {
  curriculum: TSFixMe;
} & ProductFragment['content'];

export interface ChildUserProduct extends Omit<UserProduct, 'children'> {}

export type DerivedCollectionFields = {
  firstProductContentId: string;
  firstProductContentType: string;
  currentProductContentId?: string;
  currentProductContentType?: string;
  aggregateContentDuration?: number;
};

export interface UserProduct extends ProductFragment {
  purchased?: boolean;
  userPerformance?: UserPerformance;
  contentType: ProductContentTypeEnum;
  contentId: string | null;
  contentIdForPerf?: string | null;
  contentDuration?: number;
  content: ProductCustomContent;
  certificate?: CertificateFields;
  parentProduct?: Omit<UserProduct, 'children'>;
  isChildProduct?: boolean;
  children: ChildUserProduct[];
  derivedFields: DerivedProductFields;
  derivedCollectionFields?: DerivedCollectionFields;
}

export type GenericProduct = UserProduct | ChildUserProduct;

export type State = {
  contentId: string;
  progress: number;
  completionStatus: string;
  completedOn: string;
  certificate?: IssuedCertificateFragmentFragment;
};
