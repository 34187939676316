import { compact, flatten } from 'lodash';
import { ProductTypeEnum } from 'types/schema';

const getContentIdsFromProducts = (products: TSFixMe[]) => {
  let contentIds = compact(
    flatten(
      products.map((p: TSFixMe) => {
        if (p.type === ProductTypeEnum.Collection) {
          const childrenContentId = p.children?.map((cp: TSFixMe) => cp?.content?.id);
          const collectionContentId = p?.contentIdForPerf;

          return [...childrenContentId, collectionContentId];
        }

        return p?.content?.id;
      }),
    ),
  );

  return contentIds;
};

export default getContentIdsFromProducts;
