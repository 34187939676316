import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';

import { IssuedCertificateFragmentFragmentDoc } from '../../fragments/generated/IssuedCertificate';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CourseV2PerformancesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.PerformancesFilters>;
  first?: Types.InputMaybe<Types.Scalars['Float']>;
}>;

export type CourseV2PerformancesQuery = {
  __typename?: 'Query';
  contentPerformances: {
    __typename?: 'ContentPerformanceConnection';
    edges?: Array<{
      __typename?: 'ContentPerformanceEdge';
      node:
        | { __typename?: 'ArticlePerformanceType' }
        | { __typename?: 'BasePerformanceSchema' }
        | {
            __typename?: 'CoursePerformanceType';
            id: string;
            progress: number;
            status: Types.PerformanceStatusEnum;
            entityId: string;
            completedAt?: string | null;
            issuedCertificate?: {
              __typename?: 'CertificateType';
              entityId: string;
              userId: string;
              id: string;
              sharedId: string;
              name: string;
              createdAt: string;
              html: string;
              status: Types.CertificateStatusEnum;
              image?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
              dimensions: { __typename?: 'CertificateDimensions'; width: number; height: number };
              pdf?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
            } | null;
          }
        | { __typename?: 'EventPerformanceType' }
        | { __typename?: 'ExercisePerformanceType' }
        | { __typename?: 'ExternalContentPerformanceType' }
        | { __typename?: 'HomeworkPerformanceType' }
        | {
            __typename?: 'PathPerformanceType';
            id: string;
            status: Types.PerformanceStatusEnum;
            entityId: string;
            completedAt?: string | null;
            path_progress?: number | null;
            issuedCertificate?: {
              __typename?: 'CertificateType';
              entityId: string;
              userId: string;
              id: string;
              sharedId: string;
              name: string;
              createdAt: string;
              html: string;
              status: Types.CertificateStatusEnum;
              image?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
              dimensions: { __typename?: 'CertificateDimensions'; width: number; height: number };
              pdf?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
            } | null;
          }
        | { __typename?: 'QuestionPerformanceType' }
        | { __typename?: 'QuizPerformanceType' }
        | { __typename?: 'ScormPerformanceType' }
        | { __typename?: 'SubTaskPerformanceType' }
        | { __typename?: 'SurveyPerformanceType' }
        | { __typename?: 'TaskPerformanceType' }
        | { __typename?: 'TaskSectionPerformanceType' };
    }> | null;
  };
};

export const CourseV2PerformancesDocument = gql`
  query CourseV2Performances($filters: PerformancesFilters, $first: Float) {
    contentPerformances(filters: $filters, first: $first) {
      edges {
        node {
          ... on PathPerformanceType {
            id
            path_progress: progress
            status
            entityId
            completedAt
            issuedCertificate {
              ...IssuedCertificateFragment
              entityId
              userId
            }
          }
          ... on CoursePerformanceType {
            id
            progress
            status
            entityId
            completedAt
            issuedCertificate {
              ...IssuedCertificateFragment
              entityId
              userId
            }
          }
        }
      }
    }
  }
  ${IssuedCertificateFragmentFragmentDoc}
`;

export function useCourseV2PerformancesQuery(
  options?: Omit<Urql.UseQueryArgs<CourseV2PerformancesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CourseV2PerformancesQuery, CourseV2PerformancesQueryVariables>({
    query: CourseV2PerformancesDocument,
    ...options,
  });
}
