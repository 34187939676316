import { cloneDeep, map } from 'lodash';
import { useUser } from 'modules/context/Contexts/User';
import { ProductTypeEnum, TransactionPaymentStatusEnum } from 'types/schema';

import { useProductTransactionsQuery } from '../graphql/queries/generated/transactions';
import { UserProduct } from '../types';

const useUserProductTransactions = (products: UserProduct[]) => {
  const user = useUser();

  const [{ data, fetching, error }] = useProductTransactionsQuery({
    pause: !user.id,
    variables: {
      userIds: [user.id],
      productIds: map(products, 'id'),
      status: TransactionPaymentStatusEnum.Success,
    },
    requestPolicy: 'cache-and-network',
  });

  const transactions = data?.transactions;

  if (transactions) {
    transactions.edges?.forEach((t) => {
      const product = products.find((p: TSFixMe) => p.id === t.node.productId);
      /**
       * setting purchased flag inside Product to `true`
       */
      if (product) {
        product.purchased = true;

        /**
         * setting purchased flag inside Product's children products to `true`
         */
        if (product.type === ProductTypeEnum.Collection) {
          product.children.forEach((cp) => (cp.purchased = true));
        }
      }
    });
  }

  return { products, fetching, error };
};

export default useUserProductTransactions;
