import { useUser } from 'modules/context/Contexts/User';
import transformPerformance from 'modules/product/utils/transformCourseV2Performance';
import { PerformancesFilters } from 'types/schema';

import { useCourseV2PerformancesQuery } from '../graphql/queries/generated/courseV2Performances';

/**
 * Course v2 (classroom/enterprise courses) performances
 */
const useCourseV2Performances = ({
  pause,
  contentIds,
  first = 1000,
}: {
  pause: boolean;
  contentIds: string[];
  first?: number;
}) => {
  const user = useUser();

  const filters: PerformancesFilters = { userIds: [user.id] };

  if (contentIds) {
    filters.entityIds = contentIds;
  }

  const [{ fetching, error, data, stale }] = useCourseV2PerformancesQuery({
    variables: { filters: filters, first },
    pause: !user.id || pause,
    requestPolicy: 'cache-and-network',
  });

  const transformedPerformances = transformPerformance(
    data?.contentPerformances?.edges?.map((e) => e?.node),
  );

  return { fetching, error, performances: transformedPerformances, stale };
};
export default useCourseV2Performances;
