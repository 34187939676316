import { useCallback, useEffect, useState } from 'react';
import validateJWT from 'helpers/validateJWT';
import useRestQuery from 'hooks/useRestQuery';
import { useLyearnCorpAPIToken } from 'modules/contentAffiliations/affiliations/lyearnCorp/hooks/useLyearnCorpAPIToken';
import { useUser } from 'modules/context/Contexts/User';
import transformPerformance from 'modules/product/utils/transformLyearnCoursePerformance';

import fetchContentStates from '../requests/fetchContentStates';

/**
 *  Lyearn Corp courses performances (doesn't accept content ids as filter)
 * */
const useLyearnCoursePerformances = ({ pause }: { pause: boolean }) => {
  const user = useUser();

  const [fetchingToken, token, executeGenerateCorpToken] = useLyearnCorpAPIToken({
    pause: !user.id || pause,
  });

  const fetchStates = useCallback(() => {
    if (validateJWT(token)) {
      return fetchContentStates({ token, corporateUserId: user.corporateUserId });
    } else {
      executeGenerateCorpToken();
    }
  }, [token, executeGenerateCorpToken, user.corporateUserId]);

  const query = useRestQuery(['productStates'], () => fetchStates(), {
    enabled: !!token && !pause,
  });

  return {
    fetching: query.isLoading || (fetchingToken && !token),
    error: query.isError,
    performances: transformPerformance(query.data as TSFixMe),
    execute: fetchStates,
  };
};
export default useLyearnCoursePerformances;
