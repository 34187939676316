import url from 'helpers/url';

const fetchLyearnCorpAPIToken: TSFixMe = async () => {
  const response = await fetch(`${url}/external/generateTokens/lyearnCorporateTokens`, {
    method: 'POST',
    body: JSON.stringify({
      action: 'fetchAPIToken',
    }),
    headers: {
      'content-type': 'application/json',
    },
  });

  return await response.json();
};

export default fetchLyearnCorpAPIToken;
