import { uniq } from 'lodash';
import useCourseV2Performances from 'modules/contentAffiliations/affiliations/courseV2/hooks/useCourseV2Performances';
import useLyearnCoursePerformances from 'modules/contentAffiliations/affiliations/lyearnCorp/hooks/useLyearnCoursePerformances';
import { UserProduct } from 'modules/product/types';
import getContentIdsFromProducts from 'modules/product/utils/getContentIdsFromProducts';
import mergePerformacesWithProducts from 'modules/product/utils/mergePerformacesWithProducts';
import { ProductContentTypeEnum } from 'types/schema';

const useUserProductPerformance = (products: UserProduct[]) => {
  let allProducts = products;
  /**
   * filter and get only purchased products to fetch performance of
   */
  const purchasedProducts = products.filter((p: UserProduct) => p.purchased);

  /**
   * filter and get Course types (Lyearn corp / CourseV2)
   */
  const performanceProviders = uniq(
    purchasedProducts.map((p: UserProduct) => p?.contentType),
  ) as ProductContentTypeEnum[];

  /**
   *  Lyearn Corp courses performances (doesn't accept content ids as filter)
   * */
  const { performances: performances1, fetching: fetching_1 } = useLyearnCoursePerformances({
    pause: !performanceProviders.includes(ProductContentTypeEnum.CorporateCourse),
  });

  /**
   * Course v2 (classroom/enterprise courses) performances
   */
  const {
    performances: performances2,
    fetching: fetching_2,
    stale: stale2,
  } = useCourseV2Performances({
    pause: !performanceProviders.includes(ProductContentTypeEnum.Course),
    contentIds: getContentIdsFromProducts(purchasedProducts),
  });

  return {
    products: mergePerformacesWithProducts(allProducts, [...performances1, ...performances2]),
    fetching: fetching_1 || fetching_2,
    isStale: stale2,
  };
};

export default useUserProductPerformance;
