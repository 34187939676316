import { CreateCheckoutSessionMutation } from 'modules/product/graphql/mutations/generated/createCheckoutSession';
import { PurchasableProduct, UserProduct } from 'modules/product/types';
import { User } from 'types/interfaces';
import { MediaTypeEnum, RazorpayCheckoutSessionMutationPayload } from 'types/schema';

import { PaymentProvidersInput } from '../types';

const razorpayCheckout = async ({
  checkoutPayload,
  product,
  redirectUrl,
  user,
}: {
  checkoutPayload: CreateCheckoutSessionMutation['createCheckoutSession'];
  product: PurchasableProduct | UserProduct;
  redirectUrl: string;
  user: User;
  sitePaymentInfo: PaymentProvidersInput['sitePaymentInfo'];
}) => {
  const RPCheckoutPayload = checkoutPayload as RazorpayCheckoutSessionMutationPayload;
  const razorpayOptions = {
    key: RPCheckoutPayload.razorpayKeyId,
    amount: RPCheckoutPayload.amount,
    currency: product?.pricingParameters?.currency,
    name: product.name,
    description: product.description,
    image: product.media.find((mediaItem) => mediaItem.type === MediaTypeEnum.Image)?.url || '',
    order_id: RPCheckoutPayload.orderId,
    callback_url: `${redirectUrl}?status=success`,
    handler: () => {
      /* paymnent success */
      window.location.href = `${redirectUrl}?status=success`;
    },
    retry: {
      enabled: false,
    },
    modal: {
      ondismiss: () => {
        window.location.href = `${redirectUrl}`;
      },
      confirm_close: true,
    },
    prefill: {
      name: user.name,
      email: user.emailId,
      contact: '',
    },
    theme: {
      color: '#3399cc',
    },
  };

  if (window.Razorpay) {
    var razorpayInstance = new window.Razorpay(razorpayOptions);
    razorpayInstance.on('payment.failed', function () {
      window.location.href = `${redirectUrl}?status=failed`;
    });
    razorpayInstance.open();
  } else {
    return false;
  }

  return true;
};

export default razorpayCheckout;
