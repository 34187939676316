import { isBefore, isValid } from 'date-fns';

const isDiscountValid = (discountValidity: string) => {
  const today = new Date();
  const isValidDate = discountValidity && isValid(new Date(discountValidity));

  if (!isValidDate) {
    return true;
  }

  if (isValidDate) {
    const discountEndDate = new Date(discountValidity);
    const isExpired = isBefore(discountEndDate, today);

    if (isExpired) {
      return false;
    }

    return true;
  }

  return false;
};

export default isDiscountValid;
