import {
  CourseContent_ContentType_Fragment,
  CourseContent_CourseContentType_Fragment,
} from 'data-op/fragments/generated/content';
import { ProductFragment } from 'data-op/fragments/generated/product';
import { ProductContentTypeEnum } from 'types/schema';
const getProductContentFields = (content: ProductFragment['content']) => {
  const courseContent = content as CourseContent_CourseContentType_Fragment;
  const corpCourseContent = content as CourseContent_ContentType_Fragment;

  if (!courseContent && !corpCourseContent) {
    return {
      id: '',
      type: ProductContentTypeEnum.CorporateCourse,
      duration: 0,
    };
  }

  if (corpCourseContent.type === ProductContentTypeEnum.CorporateCourse) {
    return {
      id: corpCourseContent.id,
      type: ProductContentTypeEnum.CorporateCourse,
      duration: corpCourseContent.duration,
    };
  }
  return {
    id: courseContent.id,
    type: ProductContentTypeEnum.Course as ProductContentTypeEnum,
    duration: courseContent.course_duration,
  };
};

export default getProductContentFields;
