import { cloneDeep } from 'lodash';

import { UserProduct } from '../types';
import useDerivedProductFields from './useDerivedProductFields';
import useTransformProducts from './useTransformProducts';
import useUserProductPerformance from './useUserProductPerformance';
import useUserProductTransactions from './useUserProductTransactions';

const useUserProducts = (products: UserProduct[]) => {
  /* Todo remove cloneDeep */
  const allProducts = products;

  /**
   * Transform products, insert children products into collection type of product
   */
  const { products: transformedProducts } = useTransformProducts(allProducts);

  /**
   * Get products with added info if they are purchased products
   */
  const { products: productsWithUserPurchaseInfo, fetching: fetchingTransactions } =
    useUserProductTransactions(transformedProducts);

  /**
   * Get product content-user progress of the user purchased products
   */
  const {
    products: productPerformanceInfo,
    fetching: fetchingPerformances,
    isStale,
  } = useUserProductPerformance(productsWithUserPurchaseInfo);

  /**
   * Adds calculated fields to Product
   */
  const { products: productWithDerivedFields } = useDerivedProductFields(productPerformanceInfo);

  /**
   * Retuen modified new product array
   */
  return {
    products: productWithDerivedFields,
    fetching: fetchingTransactions || fetchingPerformances,
    isStale,
  };
};

export default useUserProducts;
