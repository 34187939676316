import { useCallback, useEffect } from 'react';
import { useUser } from 'modules/context/Contexts/User';
import { useCreateCheckoutSessionMutation } from 'modules/product/graphql/mutations/generated/createCheckoutSession';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { TransactionPaymentProviderEnum } from 'types/schema';
import sanitizeURL from 'utils/sanitizeUrl';

import Cookie from '@lyearn/core/utils/cookie';

import handlePaymentProvider from './handlePaymentProvider';
import { MutationInput } from './types';

const useCheckoutSessionAndRedirect = ({
  productId,
  quantity = 1,
  redirectUrl,
  product,
}: MutationInput) => {
  const user = useUser();
  const { site } = useInstanceConfig();
  const [{ fetching, error, data }, createSession] = useCreateCheckoutSessionMutation();

  const checkout = useCallback(async () => {
    const input = {
      cartDetails: [{ productId, quantity }],
      storeId: Cookie.get('storeId')!,
      redirectURL: redirectUrl || window.location.href,
    };

    const response = await createSession(input);

    return { paymentProvider: response.data?.createCheckoutSession.paymentProvider };
  }, [createSession, productId, quantity, redirectUrl]);

  useEffect(() => {
    if (!fetching && data) {
      const response = data?.createCheckoutSession;

      if (response?.success !== true || error) {
        console.error('Purchase product mutation failed.', error, response);
        console.log('Input:', {
          cartDetails: [{ productId, quantity }],
          storeId: Cookie.get('storeId')!,
          redirectURL: redirectUrl || window.location.href,
        });

        window.location.href = sanitizeURL(redirectUrl);
        throw new Error('Failed product purchase.');
      }
      const checkoutPayload = data.createCheckoutSession;

      if (checkoutPayload.paymentProvider === TransactionPaymentProviderEnum.Na) {
        return;
      }
      handlePaymentProvider({
        checkoutPayload: data.createCheckoutSession,
        product,
        redirectUrl: redirectUrl!,
        user,
        sitePaymentInfo: site.payment,
      });
    }
  }, [data, fetching, productId, quantity, redirectUrl, product, user, error]);

  return { checkout, fetching };
};

export default useCheckoutSessionAndRedirect;
