import {
  CourseContent_ContentType_Fragment,
  CourseContent_CourseContentType_Fragment,
} from 'data-op/fragments/generated/content';
import { ProductContentTypeEnum, ProductTypeEnum, ResourceTypeEnum } from 'types/schema';

import { ChildUserProduct, GenericProduct, UserProduct } from '../types';
import getProductContentFields from '../utils/getProductContentFields';

const setProductContent = (product: GenericProduct) => {
  const { id, type, duration } = getProductContentFields(product.content);

  /* contentId */
  product.contentId = id;
  product.contentType = type;
  product.contentDuration = duration || 0;

  return product;
};

const useProductContentTransform = (products: UserProduct[]) => {
  products.forEach((p) => {
    if (p.type === ProductTypeEnum.Collection) {
      const firstProductContentId = (p.children?.[0].content as any)?.id;

      const firstProductContentType =
        (p.children?.[0].content as any)?.type ?? (p.children?.[0].content as any)?.course_type;

      p.contentIdForPerf = (p.content as any)?.id;

      /**
       * Set contentId and contentType on product level for easy and non-conditional access
       */
      p.contentId = firstProductContentId;
      p.contentType = firstProductContentType;

      p.derivedCollectionFields = {
        firstProductContentId,
        firstProductContentType,
      };

      const contentArray = p.children.map((cp) => cp.content);

      p.derivedCollectionFields.aggregateContentDuration = contentArray.reduce(
        (accumulator, content) =>
          accumulator +
          ((content as CourseContent_ContentType_Fragment)?.duration ||
            (content as CourseContent_CourseContentType_Fragment)?.course_duration),
        0,
      );

      /**
       * Set contentDuration on product level for easy and non-conditional access
       */
      p.contentDuration = p.derivedCollectionFields.aggregateContentDuration;

      p.children?.forEach?.((cp: ChildUserProduct) => {
        setProductContent(cp);
      });
    } else {
      setProductContent(p);
    }
  });
  return { products };
};

export default useProductContentTransform;
