import { PurchasableProduct, UserProduct } from 'modules/product/types';
import { User } from 'types/interfaces';
import { StripeCheckoutSessionMutationPayload } from 'types/schema';

import { PaymentProvidersInput } from '../types';

const stripeCheckout = async ({
  checkoutPayload,
  sitePaymentInfo,
}: {
  checkoutPayload: PaymentProvidersInput['checkoutPayload'];
  product: PurchasableProduct | UserProduct;
  redirectUrl: string;
  user: User;
  sitePaymentInfo: PaymentProvidersInput['sitePaymentInfo'];
}) => {
  // Redirect to Checkout.
  const getStripe = await import(/* webpackChunkName: "stripe" */ 'utils/get-stripe');
  const stripe = await getStripe.default(sitePaymentInfo?.stripe?.accountId);
  const { error } = await stripe!.redirectToCheckout({
    sessionId: (checkoutPayload as StripeCheckoutSessionMutationPayload).sessionId!,
  });

  // If `redirectToCheckout` fails due to a browser or network
  // error, display the localized error message to your customer
  // using `error.message`.
  console.warn(error.message);

  return true;
};

export default stripeCheckout;
