import {
  CourseContent_ContentType_Fragment,
  CourseContent_CourseContentType_Fragment,
} from 'data-op/fragments/generated/content';
import calculateDiscount from 'helpers/calculateDiscount';
import { omit } from 'lodash';
import {
  DiscountTypeEnum,
  PerformanceStatusEnum,
  ProductContentTypeEnum,
  ResourceTypeEnum,
} from 'types/schema';

import { GenericProduct, UserProduct, UserProductStatusEnum } from '../types';

const getDefaultDerivedFields = () => ({
  userProductStatus: UserProductStatusEnum.NotPurchased,
  enrolled: false,
  notStarted: false,
  inProgress: false,
  completed: false,
  purchaseInfo: {
    isFree: false,
    isDiscounted: false,
    price: 0,
    finalPrice: 0,
    discountAmount: 0,
    discountValidity: '',
    discountType: DiscountTypeEnum.Fixed,
    currency: '',
    productId: '',
    purchasableProduct: null,
  },
});

const addDerivedFields = (p: GenericProduct, parentProduct?: UserProduct) => {
  const courseContent = p.content as CourseContent_CourseContentType_Fragment;
  const corpCourseContent = p.content as CourseContent_ContentType_Fragment;

  if (corpCourseContent?.type === ProductContentTypeEnum.CorporateCourse) {
    p.contentType = ProductContentTypeEnum.CorporateCourse;
    p.contentDuration = corpCourseContent.duration || 0;
  } else if (courseContent?.course_type === ResourceTypeEnum.Course) {
    p.contentType = ProductContentTypeEnum.Course;
    p.contentDuration = courseContent.course_duration;
  }

  /**
   ***** derivedFields -- DerivedProductFields
   */
  p.derivedFields = getDefaultDerivedFields();

  /* pricing */
  const pricingParameters = parentProduct
    ? parentProduct?.pricingParameters!
    : p.pricingParameters!;

  if (pricingParameters) {
    const { price, discountValue, discountedPrice, isDiscounted } =
      calculateDiscount(pricingParameters);

    p.derivedFields.purchaseInfo = {
      isFree: price === 0 || discountedPrice === 0,
      isDiscounted: !!isDiscounted,
      price,
      discountType: pricingParameters.discount?.type!,
      finalPrice: discountedPrice,
      discountAmount: discountValue,
      discountValidity: pricingParameters.discount?.validity || '',
      currency: pricingParameters?.currency! || '',
      productId: parentProduct ? parentProduct.id : p.id,
      purchasableProduct: parentProduct ? omit(parentProduct, 'children') : null,
    };
  }

  if (!p.content) {
    return;
  }

  /* userProductStatus | enrolled | notStarted |  inProgress | completed  */
  if (p?.userPerformance) {
    p.derivedFields.enrolled = true;
  }
  if (p?.userPerformance?.progress && p?.userPerformance?.progress < 1) {
    p.derivedFields.userProductStatus = PerformanceStatusEnum.NotStarted;
    p.derivedFields.notStarted = true;
  } else if (
    p?.userPerformance?.progress &&
    p?.userPerformance?.progress === 100 &&
    p?.userPerformance?.completionStatus === PerformanceStatusEnum.Completed
  ) {
    p.derivedFields.userProductStatus = PerformanceStatusEnum.Completed;
    p.derivedFields.completed = true;
  } else {
    p.derivedFields.userProductStatus = PerformanceStatusEnum.InProgress;
    p.derivedFields.inProgress = true;
  }
};

const useDerivedProductFields = (products: UserProduct[]) => {
  /* contentType | contentDuration */
  products.forEach((p) => {
    addDerivedFields(p);

    (p as UserProduct).children?.map?.((cp) => {
      addDerivedFields(cp, p as UserProduct);
    });
  });

  return { products, fetching: false };
};

export default useDerivedProductFields;
