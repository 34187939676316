import { PerformanceStatusEnum, ProductTypeEnum } from 'types/schema';

import { UserProduct } from '../types';

/**
 * loop and merge performances to products by comparing `contentId` from inside product.content.id
 */
const mergePerformacesWithProducts = (products: UserProduct[], performances: TSFixMe) => {
  /**
   * For all products loop and add performance by matching contentIds
   */
  products.forEach((p) => {
    if (!p.purchased) {
      return;
    }

    if (p.type === ProductTypeEnum.Course) {
      const contentPerformance = performances.find(
        (perf: TSFixMe) => perf.contentId === p.contentId,
      );

      if (contentPerformance) {
        p.userPerformance = contentPerformance;
      }
    }

    if (p.type === ProductTypeEnum.Collection) {
      const collectionPerformance = performances.find(
        (perf: TSFixMe) => perf.contentId === p?.contentIdForPerf,
      );

      p.userPerformance = {
        contentId: '',
        completedOn: '',
        progress: 0,
        certificate: collectionPerformance?.certificate,
        completionStatus: PerformanceStatusEnum.NotStarted,
      };

      let totalProgress = 0;
      p.children.forEach((cp: TSFixMe) => {
        const childPerformance = performances.find(
          (perf: TSFixMe) => perf.contentId === cp.contentId,
        );

        if (childPerformance) {
          cp.userPerformance = childPerformance;

          totalProgress += childPerformance?.progress;
        }
      });

      /* average child progress and add to `totalProgress` */
      totalProgress = Math.round(totalProgress / p.children.length);

      p.userPerformance.progress = totalProgress;
      p.userPerformance.completionStatus =
        totalProgress === 100
          ? PerformanceStatusEnum.Completed
          : p.purchased
          ? PerformanceStatusEnum.InProgress
          : PerformanceStatusEnum.NotStarted;
    }
  });

  return products;
};

export default mergePerformacesWithProducts;
