import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCheckoutSessionMutationVariables = Types.Exact<{
  cartDetails: Array<Types.CartDetails> | Types.CartDetails;
  storeId: Types.Scalars['String'];
  redirectURL: Types.Scalars['String'];
}>;

export type CreateCheckoutSessionMutation = {
  __typename?: 'Mutation';
  createCheckoutSession:
    | {
        __typename?: 'InvalidCheckoutSessionMutationPayload';
        success?: boolean | null;
        paymentProvider: Types.TransactionPaymentProviderEnum;
        errorCode?: string | null;
      }
    | {
        __typename?: 'NACheckoutSessionMutationPayload';
        success?: boolean | null;
        paymentProvider: Types.TransactionPaymentProviderEnum;
        transactionIds?: Array<string> | null;
      }
    | {
        __typename?: 'RazorpayCheckoutSessionMutationPayload';
        success?: boolean | null;
        paymentProvider: Types.TransactionPaymentProviderEnum;
        orderId?: string | null;
        amount?: number | null;
        razorpayKeyId?: string | null;
      }
    | {
        __typename?: 'StripeCheckoutSessionMutationPayload';
        success?: boolean | null;
        sessionId?: string | null;
        paymentProvider: Types.TransactionPaymentProviderEnum;
      };
};

export const CreateCheckoutSessionDocument = gql`
  mutation createCheckoutSession(
    $cartDetails: [CartDetails!]!
    $storeId: String!
    $redirectURL: String!
  ) {
    createCheckoutSession(
      data: { cartDetails: $cartDetails, storeId: $storeId, redirectURL: $redirectURL }
    ) {
      ... on StripeCheckoutSessionMutationPayload {
        success
        sessionId
        paymentProvider
      }
      ... on RazorpayCheckoutSessionMutationPayload {
        success
        paymentProvider
        orderId
        amount
        razorpayKeyId
      }
      ... on InvalidCheckoutSessionMutationPayload {
        success
        paymentProvider
        errorCode
      }
      ... on NACheckoutSessionMutationPayload {
        success
        paymentProvider
        transactionIds
      }
    }
  }
`;

export function useCreateCheckoutSessionMutation() {
  return Urql.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(
    CreateCheckoutSessionDocument,
  );
}
