import { PerformanceStatusEnum } from 'types/schema';

import { State } from '../types';

const performanceMap: Record<string, PerformanceStatusEnum> = {
  complete: PerformanceStatusEnum.Completed,
  inProgress: PerformanceStatusEnum.InProgress,
  notStarted: PerformanceStatusEnum.NotStarted,
};

const transformPerformance = (performances: any[] = []) => {
  const states: State[] = [];
  performances?.forEach?.((item) => {
    if (item.contentId) {
      states.push({
        ...item,
        completionStatus: performanceMap[item.completionStatus],
        progress: item.scaledProgressMeasure,
      });
    }
  });
  return states;
};

export default transformPerformance;
