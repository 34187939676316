import { TransactionPaymentProviderEnum } from 'types/schema';

import razorpayCheckout from './razorpay';
import stripeCheckout from './stripe';

const PaymentProviders = {
  [TransactionPaymentProviderEnum.Stripe]: stripeCheckout,
  [TransactionPaymentProviderEnum.Razorpay]: razorpayCheckout,
  [TransactionPaymentProviderEnum.Na]: () => false,
  [TransactionPaymentProviderEnum.Invalid]: () => false,
};

export default PaymentProviders;
