import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductTransactionsQueryVariables = Types.Exact<{
  userIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  productIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  status: Array<Types.TransactionPaymentStatusEnum> | Types.TransactionPaymentStatusEnum;
}>;

export type ProductTransactionsQuery = {
  __typename?: 'Query';
  transactions: {
    __typename?: 'PaginatedTransactions';
    edges?: Array<{
      __typename?: 'TransactionEdge';
      node: { __typename?: 'TransactionType'; productId: string };
    }> | null;
  };
};

export const ProductTransactionsDocument = gql`
  query ProductTransactions(
    $userIds: [String!]
    $productIds: [String!]
    $status: [TransactionPaymentStatusEnum!]!
  ) {
    transactions(filters: { userIds: $userIds, productIds: $productIds, status: $status }) {
      edges {
        node {
          productId
        }
      }
    }
  }
`;

export function useProductTransactionsQuery(
  options: Omit<Urql.UseQueryArgs<ProductTransactionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ProductTransactionsQuery, ProductTransactionsQueryVariables>({
    query: ProductTransactionsDocument,
    ...options,
  });
}
