const fetchContentStates = async ({
  token,
  corporateUserId,
}: {
  token: string;
  corporateUserId: string;
}) => {
  const response = await fetch('https://prod-api.lyearn.com/external/fetch-corporate-data/states', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      lyearn_corporate_token: token,
    },
    body: JSON.stringify({
      action: 'fetchStates',
      userId: corporateUserId,
    }),
  });

  return await response.json();
};

export default fetchContentStates;
