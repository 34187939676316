import { QueryObserverBaseResult } from 'react-query';
import validateJWT from 'helpers/validateJWT';
import useRestQuery from 'hooks/useRestQuery';

import fetchLyearnCorpAPIToken from '../requests/fetchLyearnCorpAPIToken';

type Response = { tokens: { accessToken: string } };

/* const validTokenExists = () => {
  if (validateJWT(token)) {
    return token;
  }
  return false;
}; */

let token = '';

function useLyearnCorpAPIToken({
  pause,
}: {
  pause: boolean;
}): [boolean, string, QueryObserverBaseResult['refetch']] {
  const enabled = (!token || !validateJWT(token)) && !pause;

  const query = useRestQuery<Response, TSFixMe>(['lyearnCorpAPIToken'], fetchLyearnCorpAPIToken, {
    enabled: enabled,
  });

  if (query.isSuccess && query.data) {
    token = query.data?.tokens?.accessToken;
  }

  return [query.isFetching, token, query.refetch];
}

export { useLyearnCorpAPIToken };
