import gql from 'graphql-tag';
import * as Types from 'types/schema';
export type IssuedCertificateFragmentFragment = {
  __typename?: 'CertificateType';
  id: string;
  sharedId: string;
  name: string;
  createdAt: string;
  html: string;
  status: Types.CertificateStatusEnum;
  image?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
  dimensions: { __typename?: 'CertificateDimensions'; width: number; height: number };
  pdf?: { __typename?: 'EntityMediaType'; url?: string | null } | null;
};

export const IssuedCertificateFragmentFragmentDoc = gql`
  fragment IssuedCertificateFragment on CertificateType {
    id
    sharedId
    name
    createdAt
    image {
      url
    }
    html
    status
    dimensions {
      width
      height
    }
    pdf {
      url
    }
  }
`;
